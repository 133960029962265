<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="addTemaSidebarAtivo"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:add-tema-sidebar-ativo', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar Tema
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{invalid}"
        ref="temaForm"
      >
        <!-- Form -->
        <b-form
          ref="temaForms"
          class="p-2"
          @submit.prevent="hitTemaCadastrar"
        >

          <b-form-group
            label="Nome"
            label-for="hitTema"
          >
            <validation-provider
              #default="{ errors }"
              name="hitTema"
              rules="required"
              :before-change="validationHitTema"
            >
              <b-form-input
                id="hitTema"
                v-model="hitTema"
                :state="errors.length > 0 ? false:null"
                placeholder="Digite o nome do tema"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || respostaApi"
            >
              Adicionar
              <b-spinner
                v-if="respostaApi"
                small
                label="Carregando"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'addTemaSidebarAtivo',
    event: 'update:add-tema-sidebar-ativo',
  },
  props: {
    addTemaSidebarAtivo: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hitTema: '',
      respostaApi: false,
    }
  },
  methods: {

    hitTemaCadastrar() {
      this.$refs.temaForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          // console.log( this.hitTema );return false;

          useJwt.hitTemaCadastrar({
            nome: this.hitTema,
          })
            .then(response => {
              this.$swal({
                title: 'Tema cadastrado!',
                text: 'O tema foi cadastrado com sucesso.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,

              }).then(result => {
                if (result.isConfirmed) {
                  this.$refs.temaForms.reset() // RESETA O FORM
                  this.$emit('update:add-tema-sidebar-ativo', false) // FECHA O SIDEBAR
                  this.$emit('recarregarTabela') // RECARREGA A TABELA
                } else {
                  this.$refs.temaForms.reset() // RESETA O FORM
                  this.$emit('update:add-tema-sidebar-ativo', false) // FECHA O SIDEBAR
                  this.$emit('recarregarTabela') // RECARREGA A TABELA
                }
              })
            })
            .catch(error => {
              // console.log( error.response );
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.respostaApi = false
            })
        }
      })
    },

    validationHitTema() {
      return new Promise((resolve, reject) => {
        this.$refs.hitTemaRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
